import { Controller } from "stimulus"
import { openPopupCenter } from "../utils"

export default class extends Controller {
  static targets = ["share"]

  connect() {
    if (navigator.share) {
      this.shareTarget.classList.remove("is-hidden")
    }
  }

  facebook() {
    openPopupCenter(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, 600, 400)
  }

  twitter() {
    openPopupCenter(`https://www.twitter.com/intent/tweet?url=${window.location.href}`, 600, 400)
  }

  share() {
    navigator.share({
      title: this.element.dataset.title,
      text: this.element.dataset.description,
      url: window.location.href
    });
  }
}
