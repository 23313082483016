import { Controller } from "stimulus"
import Cookies from 'js-cookie'


export default class extends Controller {
  static targets = ["cookieBar"]

  connect() {
    const consent = Cookies.get('cookieConsent') == "accepted";
    if (!consent) {
      this.cookieBarTarget.classList.remove("is-hidden");
    }
  }

  accept() {
    this.cookieBarTarget.classList.add("is-hidden");
  }
}
