import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["success", "error", "form"]

  onReply(event) {
    const commentNode = event.currentTarget.closest(".main-comment");
    commentNode.appendChild(this.formTarget);

    let replyInput;
    // We need to clean up the reply field before adding another one
    if (replyInput = document.getElementById("replyInput")) {
      replyInput.remove();
    }

    replyInput = document.createElement('input');
    replyInput.type = "hidden";
    replyInput.id = "replyInput";
    replyInput.name = "comment[parent_id]";
    replyInput.value = commentNode.dataset.commentId;

    this.formTarget.appendChild(replyInput);

    this.formTarget.classList.add("is-reply-form");
  }

  onReplyFinish() {
    this.element.appendChild(this.formTarget);
    this.onComplete();
  }

  onSuccess() {
    this.successTarget.classList.remove("is-hidden");
  }

  onError() {
    this.errorTarget.classList.remove("is-hidden");
  }

  onComplete() {
    this.element.appendChild(this.formTarget);
    this.formTarget.classList.remove("is-reply-form");
    let replyInput;
    if (replyInput = document.getElementById("replyInput")) {
      replyInput.remove();
    }
    this.formTarget.reset();
  }

  hideNotifications() {
    this.hideSuccess();
    this.hideError();
  }

  hideSuccess() {
    this.successTarget.classList.add("is-hidden");
  }

  hideError() {
    this.errorTarget.classList.add("is-hidden");
  }
}
