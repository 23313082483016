import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["searchBar"]

  onSearchSubmit(e) {
    e.preventDefault();
    Turbolinks.visit(`/blog/search?q=${this.searchBarTarget.value}`);
    return false;
  }
}